/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 * https://github.com/LekoArts/gatsby-starter-prismic/blob/master/src/components/SEO/SEO.jsx
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslationRaw } from '@hooks/useTranslation';
import getSymbol from '@utils/getSymbol';
import colorTheme from '@content/color-theme.json';
import { firstLanguage } from '@slices/gameStateSlice';
import {
  Mdx,
  Content,
  Symbol,
  FacebookMetaProps,
  TwitterMetaProps,
} from 'types';
/** access the meat of our symbol data based on the filename. The filename must correspond to our languages */
const metaImageLanguagedSymbolDigger = (
  s: object,
  currentLanguage: string,
): string => {
  const nodes = s?.nodes?.[0]?.data?.Content[0]?.data?.Symbol?.localFiles;
  if (!nodes) {
    return '';
  }
  if (nodes?.length > 0) {
    // return the item that matches with our default language setting
    const currentLanguageNode = nodes.find(e => e.name === currentLanguage);
    if (currentLanguageNode) {
      return currentLanguageNode.publicURL;
    }
  }
  return nodes[0].publicURL;
};

const FacebookMeta = ({
  url,
  title,
  description,
  name,
  type,
  image,
  locale,
}: FacebookMetaProps) => (
  <>
    <meta property="og:url" content={url} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    {name && <meta property="og:site_name" content={name} />}
    <meta property="og:type" content={type} />
    <meta property="og:image" content={image} />
    <meta property="og:locale" content={locale} />
    <meta property="og:image:alt" content={description} />
  </>
);

const TwitterMeta = ({ cardType }: TwitterMetaProps) => (
  <meta name="twitter:card" content={cardType} />
);

interface SeoProps {
  customTitle?: Content<Symbol, Mdx> | null;
  customDescription?: Content<Symbol, Mdx> | null;
  customImageUrl?: string | undefined | null;
}
const Seo = ({ customTitle, customDescription, customImageUrl }: SeoProps) => {
  const { site, metaSymbols, faviconSymbol } = useStaticQuery(
    graphql`
      query siteMetaQuery {
        metaSymbols: allAirtable(
          filter: {
            table: { eq: "Game Elements" }
            data: { Name: { eq: "Default Meta Info" } }
          }
        ) {
          ...AbsoluteGetFragment
        }
        faviconSymbol: allAirtable(
          filter: {
            table: { eq: "Game Elements" }
            data: { Name: { eq: "Favicon" } }
          }
        ) {
          ...AbsoluteGetFragment
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `,
  );

  const { t, g } = useTranslationRaw({
    currentLanguage: firstLanguage,
    defaultLanguage: firstLanguage,
  });

  const metaTitle = g(customTitle, false) || t('Main Title');

  const metaDescription = g(customDescription, false) || t('Default Meta Info');
  // the url env var comes from netlify built-in
  const baseUrl = process.env?.URL || '';
  const metaLang = firstLanguage;
  const metaImageUrl =
    baseUrl +
    (customImageUrl || metaImageLanguagedSymbolDigger(metaSymbols, metaLang));
  const metaUrl = site?.siteMetadata?.siteUrl;
  const { data: faviconUrl } = getSymbol(faviconSymbol, true);
  return (
    <>
      <title>{metaTitle}</title>
      <html lang={metaLang} />
      <link rel="shortcut icon" type="image/png" href={faviconUrl} />
      <meta name="description" content={metaDescription} />
      <meta name="image" content={metaImageUrl} />
      <meta name="robots" content="noarchive" />
      <meta name="theme-color" content={colorTheme.primary || '#ffffff'} />
      <FacebookMeta
        description={metaDescription}
        image={metaImageUrl}
        title={metaTitle}
        type="website"
        url={metaUrl}
        locale={metaLang}
        name={metaTitle}
      />
      <TwitterMeta cardType="summary" />
    </>
  );
};

export default Seo;
